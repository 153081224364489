.faq {
    margin-top: 64px;

    h2 {
        color: #1E1E1E;
        font-family: $font-family-sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;

        @media (max-width: 992px) {
            font-size: 20px;
        }


    }

    .card {
        flex: 1;
        padding: 16px;
        border-radius: 16px;
        border: none;
        background: #FFF;




        &-body {
            .accordion {
                //border-radius: 16px;
                //background: #FFF;

                &-item {
                    border: none;
                    background: transparent;

                    .accordion-header {
                        border: none;
                        background: none;

                        .accordion-button {
                            color: #5E248A;
                            font-family: $font-family-sans-serif;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            border: none;
                            background: none;
                            box-shadow: none;

                            @media (max-width: 992px) {
                                padding-left: 0;
                                padding-right: 0;

                            }


                        }

                    }

                    .accordion-body {
                        color: #1E1E1E;
                        font-family: $font-family-sans-serif;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 400;

                        @media (max-width: 992px) {
                            padding-left: 0;
                            padding-right: 0;

                        }

                    }
                }
            }

        }
    }
}
