header {
    padding: 20px;
    margin-top: 0;
    position: relative;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    //background: rgba(249, 247, 250, 0.98);
    background-color: rgba(94, 36, 138, 0.96);

    @media (max-width: 992px) {
        padding: 25px;
        margin-top: 0;
    }

    &.index {
        position: relative;
        margin-top: 0;
        top: 0;
        left: auto;
        right: auto;
        padding: 25px 35px 25px;
        color: white;
        border-radius: 20px;
        background-color: $secondary-bg;

        @media (max-width: 992px) {
            padding: 25px 35px 25px;
        }


        .menu {
            a {
                color: #FFF;

                &:hover {
                    color: #ffcd00;

                }
            }
        }


        h1 {
            color: #FFF;
            font-family: $font-family-sans-serif;
            font-size: 64px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            @media (max-width: 992px) {
                font-size: 48px;
            }

        }

        p {
            color: #FFF;
            font-family: $font-family-sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;

        }


        .payment {
            position: relative;

            &-logos {
                position: absolute;
                left: calc(50% - 166px);
                padding: 10px 48px;
                border-radius: 32px;
                display: flex;
                justify-content: center;
                align-items: center;

                background: black;


                @media (max-width: 992px) {
                    left: auto;
                    padding: 7px 18px;

                    margin-top: 32px;
                    position: relative;
                }

                span {
                    font-family: $font-family-sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    color: #FFF;

                    @media (max-width: 992px) {
                        font-size: 14px;
                    }

                }

                img {
                    margin-left: 16px;

                    @media (max-width: 992px) {
                        margin-left: 8px;
                    }


                }


            }


        }

        img {
            &.floating-image {
                width: auto;
                height: 260px;
                position: absolute;
            }

            &.left-image {
                top: 110px;
                left: -50px;
            }

            &.right-image {
                top: 125px;
                right: -50px;
            }
        }


    }

    .menu {
        font-weight: bold;
        display: flex;
        gap: 40px;

        @media (max-width: 992px) {
            gap: 17px;
        }

        a {
            color: #fff;
            font-family: $font-family-sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            text-decoration: none;

            &:hover {
                color: #ffcd00;

            }
        }
    }

    .btn {
        &-warning {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 3px 0;
            border-radius: 32px;

            svg {
                width: 32px;
                height: 32px;
                margin-right: 8px;

            }

            @media (max-width: 992px) {
                margin-top: 16px;
                svg {
                    width: 28px;
                    height: 28px;

                }

            }

            span {
                color: #1E1E1E;
                font-family: $font-family-sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
            }


        }
    }

    .svg-icon-card {
        display: inline-block;
        animation: sway 2s infinite ease-in-out;
    }

    @keyframes sway {
        0%, 100% {
            transform: translateX(-10px);
        }
        50% {
            transform: translateX(0px);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes swayspin {
        0% {
            animation: sway 0.75s ease-in-out;
        }
        //25% {
        //    animation: spin 0.5s ease-in-out;
        //}
        //50% {
        //    animation: sway 0.75s ease-in-out;
        //}
        //75% {
        //    animation: spin 0.5s ease-in-out;
        //}
        100% {
            animation: sway 0.75s ease-in-out;
        }
    }


}


