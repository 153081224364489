.product-view {
    .card {
        flex: 1;
        padding: 64px;
        border-radius: 16px;
        border: none;
        background: #FFF;

        @media (max-width: 992px) {
            padding: 16px;
        }

        &-body {

            .image {
                .fotorama__img {
                    height: 64px;
                }

                .fotorama__nav-wrap {
                    margin-top: 8px;
                }
            }

            .product-view__info {
                padding-left: 96px;

                @media (max-width: 992px) {
                    padding-left: 0;
                }

                h1 {
                    color: #1E1E1E;
                    font-family: $font-family-sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    //text-transform: uppercase;

                    @media (max-width: 992px) {
                        font-size: 22px;
                        text-align: center;
                    }
                }

                .price {
                    display: block;
                    justify-content: center;
                    align-items: center;
                    margin-top: 24px;
                    margin-bottom: 4px;

                    color: #1E1E1E;
                    font-family: $font-family-sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;


                    @media (max-width: 992px) {
                        display: block;
                        text-align: center;
                        margin-top: 32px;
                        margin-bottom: 4px;
                        padding: 10px 16px;

                    }



                    span {
                        color: #6e2fa6;
                        font-family: $font-family-sans-serif;
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 600;
                        //line-height: normal;
                        margin-top: 32px;
                    }
                }

                .real-price {
                    display: block;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 32px;
                    color: #1E1E1E;
                    font-family: $font-family-sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;


                    @media (max-width: 992px) {
                        display: block;
                        text-align: center;
                        margin-bottom: 32px;
                        margin-top: 0;
                        padding: 10px 16px;

                    }



                    span {
                        color: #1E1E1E;
                        font-family: $font-family-sans-serif;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        margin-left: 2px;
                        text-decoration: line-through;

                    }
                }

                .free-delivery {
                    color: #1E1E1E;
                    font-family: $font-family-sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;

                    span {
                        color: #6e2fa6;
                        font-family: $font-family-sans-serif;
                        font-style: normal;

                    }

                }

                .sold {
                    margin-bottom: 48px;

                    span {
                        display: flex;
                        justify-content: end;
                        color: #1E1E1E;
                        font-family: $font-family-sans-serif;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 600;
                        margin-bottom: 6px;
                    }

                    .progress {
                        &-bar {
                            background-color: #6e2fa6;
                        }

                    }


                }

                .how-many-tickets {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 10px;
                    //margin-top: 32px;
                    border-top: 2px solid #dedede;
                    padding-top: 24px;


                    .controls {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 40px;
                        text-align: center;

                        button {
                            width: 32px;
                            height: 32px;
                            flex-shrink: 0;
                            border-radius: 8px;
                            background: #5E248A;
                            color: #fff;
                            border: none;
                            font-size: 18px;
                            cursor: pointer;

                            &:active {
                                transform: scale(0.95);
                            }
                        }


                        span {
                            text-align: center;
                            display: flex;
                            justify-content: center;

                            &.tickets {
                                width: 150px;

                            }

                            &#ticketCount {
                                margin-left: 6px;
                            }
                        }
                    }

                    .ticketSlider {
                        -webkit-appearance: none;
                        width: 100%;
                        height: 8px;
                        background: #E2E2E2;
                        border-radius: 8px;
                        outline: none;
                        margin-bottom: 10px;

                        &::-webkit-slider-thumb {
                            -webkit-appearance: none;
                            appearance: none;
                            width: 12px;
                            height: 12px;
                            background: #5E248A;
                            border-radius: 50%;
                            cursor: pointer;
                            flex-shrink: 0;
                        }

                        &::-moz-range-thumb {
                            width: 12px;
                            height: 12px;
                            background: #5E248A;
                            border-radius: 50%;
                            cursor: pointer;
                            flex-shrink: 0;
                        }
                    }
                }

                .button {
                    margin-top: 32px;

                    .btn {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &-primary {
                            background: #ffc107 !important;
                            border: 1px solid #ffc107 !important;
                            color: #1e1e1e !important;

                            &:hover {
                                color: #fff !important;
                                border: 1px solid #6e2fa6 !important;
                                background: #6e2fa6 !important;

                                svg {
                                    fill: #fff !important;
                                }

                                span {
                                    color: #fff !important;

                                }

                            }

                            svg {
                                fill: #1e1e1e;
                                margin-right: 8px;
                            }

                            span {
                                color: #1E1E1E !important;
                                font-family: $font-family-sans-serif;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 600;
                                text-transform: uppercase;

                                @media (max-width: 992px) {
                                    font-size: 18px;

                                }


                            }
                        }
                    }
                }

                &__draw {
                    display: block;
                    justify-content: space-between;
                    align-items: center;
                    margin: 32px auto;

                    &-item {
                        width: 100%;
                        padding: 7px 10px;
                        text-align: center;
                        margin-bottom: 16px;

                        span {
                            color: #6e2fa6;
                            font-family: $font-family-sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;

                            @media (max-width: 992px) {
                                text-align: center;
                            }

                            span {
                                font-family: $font-family-sans-serif;
                                font-weight: 700;

                            }

                        }

                    }

                }




            }
        }
    }

    &__description {
        margin-top: 64px;

        .card {
            padding: 32px;
            border-radius: 16px;
            border: none;
            background: #FFF;

            &-body {
                p {
                    color: #1E1E1E;
                    font-family: $font-family-sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;

                    strong {
                        font-weight: 600;
                    }
                }

                h5 {
                    color: #1E1E1E;
                    font-family: $font-family-sans-serif;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 600;
                    margin-bottom: 32px;

                    strong {
                        color: #5E248A;
                    }

                }
            }
        }

    }

}
