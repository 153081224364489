.why-choose-us {
    margin-top: 64px;

    h2 {
        color: #1E1E1E;
        font-family: $font-family-sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;

        @media (max-width: 992px) {
            font-size: 20px;
        }

    }

    .equal-height {
        display: flex;

        .items {
            display: flex;
            flex-direction: column;

            .card {
                flex: 1;
                padding: 16px;
                border-radius: 16px;
                border: none;
                background: #FFF;

                &-body {
                    .image {
                        height: 64px;
                        margin-bottom: 24px;

                    }

                    h4 {
                        color: #1E1E1E;
                        font-family: $font-family-sans-serif;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        margin-bottom: 8px;

                    }

                    p {
                        color: #1E1E1E;
                        font-family: $font-family-sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        margin-bottom: 0;

                    }
                }
            }

        }

    }
}
