.reviews {
    margin-top: 64px;

    h2 {
        color: #1E1E1E;
        font-family: $font-family-sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;

        @media (max-width: 992px) {
            font-size: 20px;
        }

    }

    .equal-height {
        display: flex;

        .items {
            display: flex;
            flex-direction: column;

            .card {
                flex: 1;
                padding: 16px;
                border-radius: 16px;
                border: none;
                background: #FFF;

                &-body {
                    .personal-info {
                        display: flex;
                        justify-content: start;
                        align-items: center;
                        margin-bottom: 32px;

                        .image {
                            height: 64px;
                            margin-right: 32px;


                            @media (max-width: 992px) {
                                margin-right: 16px;

                            }

                            img {
                                width: 64px;
                                height: 64px;
                            }

                        }

                        &__name {
                            span.first-name {
                                display: block;
                                color: #1E1E1E;
                                font-family: $font-family-sans-serif;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;

                            }

                            span.last-name {
                                display: block;
                                color: #1E1E1E;
                                font-family: $font-family-sans-serif;
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 600;
                                margin-top: -7px;

                            }



                            .rating {
                                margin-top: 0;

                                svg {
                                    gap: 10px;
                                }
                            }
                        }


                    }

                    p {
                        color: #1E1E1E;
                        font-family: $font-family-sans-serif;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 400;
                        margin-bottom: 0;

                    }
                }
            }

        }

    }
}
