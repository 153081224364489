// Body
$body-bg: #F9F7FA;
$primary-bg: #fff;
$secondary-bg: #5E248A;
$secondary-bg: #5E248A;

$font-family-sans-serif: 'Rubik', sans-serif;
$font-size-base: 0.9rem;
$font-color: #1e1e1e;
$line-height-base: 1.6;
