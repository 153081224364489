.checkout {
    margin-top: 64px;

    h1 {
        color: #1E1E1E;
        font-family: $font-family-sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;

        @media (max-width: 992px) {
            font-size: 20px;
        }

    }

    .card {
        padding: 32px;
        margin-top: 32px;
        border-radius: 16px;
        border: none;
        background: #fff;

        @media (max-width: 992px) {
            padding: 32px 0;
        }

        &-body {
            //form {
            //    width: 100%;
            //}

            .personal-info {
                width: 100%;
                h2 {
                    color: #1B1B1B;
                    font-family: $font-family-sans-serif;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-transform: uppercase;
                    margin-bottom: 32px;
                }

                h3 {
                    color: #1B1B1B;
                    font-family: $font-family-sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-transform: uppercase;
                    //margin-bottom: 32px;
                }

                .form-group {
                    margin-bottom: 32px;

                    .form-control {
                        color: #1B1B1B;
                        font-family: $font-family-sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-transform: uppercase;
                        background: #fff;
                        padding: 10px 32px;

                        border-radius: 6px;
                        border: 1px solid #B0B0B0;

                        &:focus, &:focus-visible {
                            text-shadow: none;
                            box-shadow: none;
                            outline: none;
                            border: 1px solid red;
                        }

                        &::placeholder {
                            color: #c4c4c4;

                        }
                    }


                    select.form-control {
                        appearance: none;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        background: #fff url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23B0B0B0"><path d="M7 10l5 5 5-5z"/></svg>') no-repeat right 10px center;
                        background-size: 32px;
                        padding-right: 40px;
                        border-radius: 6px;
                        border: 1px solid #B0B0B0;
                        font-size: 16px;
                        text-transform: uppercase !important;
                        font-weight: 500;
                        color: #1B1B1B;
                        line-height: normal;
                    }

                    select.form-control option {
                        font-size: 16px;
                        text-transform: capitalize !important;
                    }

                    select.form-control:focus,
                    select.form-control:focus-visible {
                        outline: none;
                        border: 1px solid red;
                    }

                    select::-ms-expand {
                        display: none;
                    }

                }

                .payment-methods {
                    margin-top: 20px;
                    @media (max-width: 992px) {
                        margin-bottom: 48px;
                    }

                    &__text {
                        text-align: start;
                        margin: 15px 0;
                        font-family: $font-family-sans-serif;
                        color: #1E1E1E;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                    }

                    &__items {
                        display: flex;
                        gap: 28px;
                        justify-content: start;
                        align-items: center;

                        img {
                            width: 70px;
                            height: auto;
                            transition: all 0.3s ease;
                            opacity: .5;
                            display: block;

                            @media (max-width: 992px) {
                                width: 50px;
                            }
                            &:hover {
                                opacity: 1;
                            }

                        }

                    }


                }
            }

            .order-info {
                width: 100%;

                h2 {
                    color: #1B1B1B;
                    font-family: $font-family-sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    //text-transform: uppercase;

                    margin-bottom: 32px;
                }

                .table > :not(caption) > * > * {
                    background: transparent;

                }

                .table {
                    background: transparent;

                    .header {
                        span {
                            color: #1B1B1B;
                            font-family: $font-family-sans-serif;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            text-transform: uppercase;
                        }
                    }

                    .body {
                        h1 {
                            color: #1B1B1B;
                            font-family: $font-family-sans-serif;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            text-transform: uppercase;
                            margin-bottom: 16px;
                        }

                    }

                    .footer {
                        span {
                            color: #1B1B1B;
                            font-family: $font-family-sans-serif;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            text-transform: uppercase;
                        }
                        .price {
                            color: #1B1B1B;
                            text-align: center;
                            font-family: 'Roboto', serif;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            text-transform: uppercase;

                            span {
                                color: #1B1B1B;
                                text-align: center;
                                font-family: 'Roboto', serif;
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                text-transform: uppercase;

                            }
                        }

                    }
                }

                .button-box {
                    margin-top: 32px;
                    display: flex;
                    justify-content: end;

                    .btn {
                        @media (max-width: 980px) {
                            width: 100%;
                        }

                        &-primary {
                            background: #ffc107 !important;
                            border: 1px solid #ffc107 !important;
                            color: #000 !important;
                            padding: 10px 32px;

                            &:hover {
                                background: #fff !important;
                            }

                            span {
                                color: #1E1E1E !important;
                                font-family: $font-family-sans-serif;
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 600;
                            }
                        }
                    }


                }


            }

        }
    }

}
