.shopping-card {
    margin-top: 64px;

    h1 {
        color: #1E1E1E;
        font-family: $font-family-sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;

        @media (max-width: 992px) {
            font-size: 20px;
        }


    }

    .card {
        padding: 16px;
        margin-top: 32px;
        margin-bottom: 32px;
        border-radius: 16px;
        border: none;
        background: #fff;

        @media (max-width: 992px) {
            padding: 16px 0;
        }


        &-body {
            table {
                width: 100%;

                tbody {
                    tr {
                        td {
                            padding: 16px 16px;

                            @media (max-width: 992px) {
                                padding: 16px 0;
                            }

                            h5 {
                                color: #1B1B1B;
                                font-family: $font-family-sans-serif;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 700;
                                //text-transform: uppercase;

                            }


                            .price {
                                margin-top: 0;

                                div {
                                    span {
                                        color: #1E1E1E;
                                        font-family: $font-family-sans-serif;
                                        font-size: 24px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: normal;

                                        .totalProductSum {
                                            padding: 0 !important;
                                            border-radius: 0;
                                            background: transparent;
                                        }
                                    }
                                }
                            }

                            .form-group {
                                display: flex;
                                align-items: center;
                                justify-content: start;

                                input[type=number] {
                                    color: #1B1B1B;
                                    text-align: center;
                                    font-family: $font-family-sans-serif;
                                    font-size: 20px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    text-transform: uppercase;
                                    max-width: 165px;

                                    margin-left: 0;
                                    margin-right: 0;

                                    border: none;
                                    padding: 0;
                                    outline: none;
                                    appearance: textfield;

                                    &::-webkit-inner-spin-button,
                                    &::-webkit-outer-spin-button {
                                        appearance: none;
                                    }

                                }

                                button[type=button] {
                                    width: 32px;
                                    height: 32px;
                                    border-radius: 8px;
                                    background: #5E248A;
                                    color: #fff;
                                    border: none;
                                    cursor: pointer;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;

                                    &:active {
                                        transform: scale(0.95);
                                    }


                                }

                            }

                        }
                    }
                }

            }


        }
    }

    .total-cart {
        margin-left: 48px;
        margin-right: 48px;

        @media (max-width: 992px) {
            margin-left: 0;
            margin-right: 0;
        }

        h5 {
            color: #1E1E1E;
            font-family: $font-family-sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            //text-transform: uppercase;
        }

        .price {
            span {
                color: #1E1E1E;
                font-family: $font-family-sans-serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
            }
        }
    }

    .button-box {
        margin-top: 32px;
        display: flex;
        justify-content: end;

        .btn {
            @media (max-width: 980px) {
                width: 100%;
            }

            &-primary {
                background: #ffc107 !important;
                border: 1px solid #ffc107 !important;
                color: #000 !important;

                &:hover {
                    background: #fff !important;
                }

                span {
                    color: #1E1E1E !important;
                    font-family: $font-family-sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                }
            }
        }
    }

    .empty {
        text-align: center;
        margin-top: 64px;
        margin-bottom: 64px;

        h1 {
            color: #1E1E1E;
            font-family: $font-family-sans-serif;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;

        }


        p {
            color: #1B1B1B;
            font-family: $font-family-sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;

        }

        &__button-box {
            margin-top: 32px;
            display: flex;
            justify-content: center;

            .btn {
                @media (max-width: 980px) {
                    width: 100%;
                }

                &-primary {
                    background: #ffc107 !important;
                    border: 1px solid #ffc107 !important;
                    color: #000 !important;

                    &:hover {
                        background: #fff !important;
                    }

                    span {
                        color: #1E1E1E !important;
                        font-family: $font-family-sans-serif;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        text-transform: uppercase;
                    }
                }
            }
        }


    }



}
