body {
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: 400;
    font-optical-sizing: auto;
    font-size: 16px;
    color: $font-color;
    background: $body-bg;


}

a {
    outline: none;
    transition: all .2s ease-in-out;

}

.form-control {
    color: $font-color;
    font-family: $font-family-sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    border-radius: 4px;
    border: 1px solid #8c8c8c;
    transition: all .3s ease-in-out;

    &:focus,
    &:focus-visible,
    &:active,
    &:hover {
        box-shadow: none;
        border: 1px solid #a56319;
    }

    &.is-invalid {
        border-color: red;
    }
}

.btn {

    @media (max-width: 992px) {
        width: 100%;
    }



    &-primary {
        padding: 12px 32px;
        transition: all .5s ease-in-out;
        border-radius: 18px;
        background: #A56319 !important;
        border: 1px solid #A56319 !important;


        span {
            color: #fff !important;
            font-size: 16px;
            font-weight: 700;

        }

        &:focus-visible{
            border-radius: 18px;
            background: #A56319 !important;
            border: 1px solid #A56319 !important;

        }

        &:hover {
            background: $body-bg !important;
            border: 1px solid #A56319 !important;

            span {
                color: #A56319 !important;

            }

        }

    }


}

.container-fluid {
    max-width: 1400px;
}



.notification {
    width: 350px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 12px 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease-in-out;
    border-bottom-left-radius: 5px;

    &.success {
        background: #b4fce4;
        border-left: 1px solid #6ed3af;
        border-bottom: 1px solid #6ed3af;


    }

    &.error {
        background: #fcb4b4;
        border-left: 1px solid #be6c6c;
        border-bottom: 1px solid #be6c6c;
    }

    p {
        color: #252525;
        font-family: $font-family-sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        text-align: center;
        padding: 0;
        margin: 0;
    }
}


#toast-container>div {
    box-shadow: none;
    opacity: .95;

    &:hover {
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        opacity: 1;
    }

}


.toast {
    &-success {
        box-shadow: none;
        opacity: 1;
        background-color: #51a351;

        &:hover {
            box-shadow: none;
            opacity: 1;

        }


    }
}

main {
    margin-top: 32px;

}

.errors {
    margin-top: 64px;
    margin-bottom: 164px;

    h4 {
        color: $font-color;
        font-family: $font-family-sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 18px;
        text-align: left;

    }
}


@keyframes scroll {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}

.rfm-child {
    animation: fade-in 1s ease-in-out;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}



:root {
    --pause-on-hover: running;
    --pause-on-click: running;
    --width: 100%;
    //--min-width: 100%;
    --transform: none;
    --play: running;
    --direction: normal;
    --duration: 34.6109375s;
    --delay: 0s;
    --iteration-count: infinite;
    --tw-gradient-from: #f03e49;
    --tw-gradient-to: #5e248a;

    @media (max-width: 992px) {
        --duration: 27.6109375s;
        --delay: 0s;
    }

}

.rfm {
    width: 100%;
    position: fixed;
    bottom: 0;
    margin-top: 100px;
    z-index: 99999;

    &-child {
        color: #FFF;
        font-family: $font-family-sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;

        span {
            color: #ffcd00;
        }

        @media (max-width: 992px) {
            font-size: 18px;
        }

    }

    &-marquee {
        flex: 0 0 auto;
        //min-width: 100%;
        z-index: 10;
        padding-top: 8px;
        padding-bottom: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        animation: scroll var(--duration) linear var(--delay) var(--iteration-count);
        animation-play-state: var(--play);
        animation-delay: var(--delay);
        animation-direction: var(--direction);


        &-container {
            overflow-x: hidden;
            display: flex;
            flex-direction: row;
            position: relative;
            background-color: $secondary-bg;
        }


    }

}


.processing {
    background-color: #ffcd00 !important;
    cursor: not-allowed;

    span {
        color: #fff;
    }

}

.spinner-border {
    color: #fff;
    margin-left: 4px;
    margin-right: 4px;
}

//
//.notification_bot {
//    position: fixed;
//    bottom: 50px;
//    right: 0;
//    z-index: 9999999;
//    padding: 20px 30px;
//    border-radius: 16px;
//    background: #d0f5f5;
//
//}


.notification_bot {
    width: 350px;
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: rgb(237, 224, 255);
    color: #343a40;
    padding: 10px 20px;
    border-radius: 3px;
    //box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-top: 3px solid #5e248a;
    font-family: $font-family-sans-serif;
    z-index: 9999999;
    animation: fadeIn 1s ease-in-out;

    @media (max-width: 575px) {
        width: calc(100% - 40px);
        left: 20px;

    }
}

@keyframes fadeIn {
    from {
        //opacity: 0;
        transform: translateY(510px);
    }
    to {
        //opacity: 1;
        transform: translateY(0);
    }
}
