.pages {
    margin-top: 64px;

    h1 {
        color: #1E1E1E;
        font-family: $font-family-sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 46px;

        @media (max-width: 992px) {
            font-size: 20px;
        }


    }

    h3 {
        color: #5E248A;
        font-family: $font-family-sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;

        @media (max-width: 992px) {
            font-size: 18px;
        }

    }

    p {
        color: #1E1E1E;
        font-family: $font-family-sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 32px;

        @media (max-width: 992px) {
            font-size: 16px;
        }


    }

}
