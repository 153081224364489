footer {
    margin-top: 96px;
    margin-bottom: 80px;
    padding-bottom: 32px;

    @media (max-width: 992px) {
        margin-top: 64px;
        text-align: center;

    }


    .support {
        margin-top: 16px;

        a {
            color: #000;
            font-family: $font-family-sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            text-decoration: none;

            &:hover {
                color: #ffcd00;
            }

        }

    }

    nav {
        display: flex;
        justify-content: center;
        align-items: start;
        gap: 30px;
        flex-wrap: wrap;

        @media (max-width: 992px) {
            display: block;
            gap: 25px;

        }


        a {
            color: #1E1E1E;
            font-family: $font-family-sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            text-decoration: none;

            @media (max-width: 992px) {
                display: block;
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0;

            }

            }


            &:hover {
                color: #ffcd00;
            }
        }
    }

}
