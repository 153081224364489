.how-to-play {
    border-radius: 32px;
    background: #5E248A;
    padding: 32px;
    margin-top: 64px;

    h2 {
        color: #FFF;
        font-family: $font-family-sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;

        @media (max-width: 992px) {
            font-size: 20px;
        }


    }


    .equal-height {
        display: flex;

        .items {
            display: flex;
            flex-direction: column;

            .card {
                flex: 1;
                border: none;
                border-radius: 16px;
                background: #FFF;


                &-body {
                    padding: 32px;


                    @media (max-width: 992px) {
                        padding: 16px;
                    }

                    .step-box {
                        .step-number {
                            width: 64px;
                            height: 64px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background: #FFCD00;
                            border-radius: 50%;
                            margin-bottom: 8px;


                            @media (max-width: 992px) {
                                width: 48px;
                                height: 48px;
                            }


                            span {
                                color: #1E1E1E;
                                font-family: $font-family-sans-serif;
                                font-size: 32px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: normal;

                                @media (max-width: 992px) {
                                    font-size: 24px;

                                }


                            }

                        }
                        p {
                            color: #1E1E1E;
                            font-family: $font-family-sans-serif;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 600;
                            margin-bottom: 0;
                        }
                    }

                }
            }
        }
    }




}
