.slider {
    //margin-top: 88px;


    //@media (max-width: 992px) {
    //    margin-top: 190px;
    //}

    &__bg-img-hero {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
    }

    &__items {
        display: flex;
        align-items: center;
        h2 {
            color: #1e1e1e;
            font-family: $font-family-sans-serif;
            font-size: 32px;
            font-style: normal;
            font-weight: 500;

            @media (max-width: 992px) {
                font-size: 24px;
                margin-top: 20px;
                margin-bottom: 32px;
            }

            span {
                color: #1e1e1e;
                font-family: $font-family-sans-serif;
                font-size: 48px;
                font-style: normal;
                font-weight: 500;

                @media (max-width: 992px) {
                    font-size: 28px;
                    margin-top: 20px;
                    margin-bottom: 32px;
                }
            }

        }

        h5 {
            color: #1e1e1e;
            font-family: $font-family-sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;

            @media (max-width: 992px) {
                font-size: 20px;
            }


        }

        &-price {

            &-default {
                color: #6e2fa6;
                font-family: $font-family-sans-serif;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                margin-bottom: 0;

                span {
                    color: #6e2fa6;
                    font-family: $font-family-sans-serif;
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 500;
                    margin-left: 2px;
                    margin-bottom: 0;
                }
            }

            &-real {
                color: #1E1E1E;
                font-family: $font-family-sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;

                span {
                    color: #1E1E1E;
                    font-family: $font-family-sans-serif;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    margin-left: 2px;
                    text-decoration: line-through;
                    //text-decoration: none;
                }
            }


        }

        img {
            width: auto;
            height: 430px;

        }

        .button {
            margin-top: 16px;

            .btn {
                width: 200px;
                display: flex;
                align-items: center;
                justify-content: center;

                @media (max-width: 992px) {
                    width: 100%;
                }


                &-primary {
                    background: #ffc107 !important;
                    border: 1px solid #ffc107 !important;
                    color: #1e1e1e !important;

                    &:hover {
                        color: #fff !important;
                        border: 1px solid #6e2fa6 !important;
                        background: #6e2fa6 !important;

                        svg {
                            fill: #fff !important;
                        }

                        span {
                            color: #fff !important;

                        }

                    }

                    svg {
                        fill: #1e1e1e;
                        margin-right: 8px;
                    }

                    span {
                        color: #1E1E1E !important;
                        font-family: $font-family-sans-serif;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        text-transform: uppercase;

                        @media (max-width: 992px) {
                            font-size: 18px;

                        }


                    }
                }
            }
        }

    }

    .slick-dots {
        display: flex !important;
        justify-content: center;
        margin-top: 20px;
        list-style: none;
        padding: 0;
        position: absolute;
        bottom: 10px;
        //left: 45%;
    }

    .slick-dots li {
        margin: 0 5px;
    }

    .custom-dot {
        display: block;
        width: 20px;
        height: 6px;
        background-color: #6e2fa6;
        border-radius: 2px;
        transition: all 0.3s ease;
    }

    .custom-dot.active {
        background-color: #ffc107;
        width: 40px;
    }


}
