.superdeals {
    margin-top: 64px;

    @media (max-width: 992px) {
        margin-top: 64px;
    }

    h2 {
        color: #1E1E1E;
        font-family: $font-family-sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 46px;

        @media (max-width: 992px) {
            font-size: 20px;
        }

    }


    .equal-height {
        display: flex;

        .items {
            display: flex;
            flex-direction: column;

            @media(max-width: 992px) {
                margin-bottom: 54px !important;

                &:last-child {
                    margin-bottom: 0 !important;
                }
            }


            .card {
                flex: 1;
                border: none;
                background: #FFF;
                border-radius: 16px;
                transition: all 0.5s ease-in-out;

                a {
                    text-decoration: none;
                }

                &-head {
                    .image {
                        overflow: hidden;
                        width: 100%;
                        height: 261px;
                        display: flex;
                        align-items: start;
                        justify-content: center;
                        margin-bottom: 16px;

                        @media (max-width: 576px) {
                            height: auto;
                        }

                        img {
                            //min-width: 100%;
                            width: auto;
                            height: 261px;
                            transition: all 0.5s ease-in-out;
                            transform: scale(1);
                            border-top-left-radius: 16px;
                            border-top-right-radius: 16px;

                        }

                    }
                }

                &-body {
                    text-align: left;
                    padding: 8px 16px 16px;

                    &__superdeal {
                        width: 100%;
                        padding: 6px 0;
                        //border-radius: 32px;
                        //background: #e3e3e3;
                        margin: 24px auto;
                        //margin-top: -37px;
                        text-align: center;


                        span {
                            color: #6e2fa6;
                            font-family: $font-family-sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;

                            @media (max-width: 992px) {
                                text-align: center;
                            }

                            span {
                                font-weight: 700;

                            }


                        }
                    }

                    .title {
                        min-height: 54px;

                        h5 {
                            color: #1E1E1E;
                            font-family: $font-family-sans-serif;
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 500;
                            text-transform: none;
                        }
                    }

                    .sold {
                        margin-top: 16px;
                        margin-bottom: 16px;

                        span {
                            display: flex;
                            justify-content: end;
                            color: #1E1E1E;
                            font-family: $font-family-sans-serif;
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 600;
                            margin-bottom: 6px;
                        }

                        .progress {
                            &-bar {
                                background-color: #6e2fa6;
                            }

                        }


                    }
                }

                &-footer {
                    padding: 16px 16px 16px;
                    border: none;
                    border-top: 1px solid #dedede;
                    background: transparent;

                    &__price {
                        color: #6e2fa6;
                        font-family: $font-family-sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;

                        span {
                            color: #6e2fa6;
                            font-family: $font-family-sans-serif;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 500;
                            margin-left: 2px;
                        }
                    }

                    &__real-price {
                        color: #1E1E1E;
                        font-family: $font-family-sans-serif;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;

                        span {
                            color: #1E1E1E;
                            font-family: $font-family-sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            margin-left: 2px;
                            text-decoration: line-through;
                            //text-decoration: none;
                        }
                    }

                    &__add-to-cart {
                        width: 44px !important;
                        height: 44px !important;
                        border: 1px solid #ffc107;
                        border-radius: 8px;
                        padding: 8px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: #ffc107;

                        &:disabled {
                            background: transparent;
                        }

                        svg {
                            fill: #fff;
                        }

                        &:hover {
                            border: 1px solid #ffc107;
                            background: transparent;

                            svg {
                                fill: #ffc107;
                            }

                        }
                    }

                }

                &:hover {
                    .card-head {
                        .image {
                            img {
                                //transform: scale(1.1);
                            }
                        }
                    }
                }


                &:hover {
                    transform: scale(1.05);
                }
            }

        }
    }


    .btn {
        &-more {
            padding: 12px 32px;
            transition: all .5s ease-in-out;
            border-radius: 18px;
            background: $secondary-bg !important;
            border: 1px solid $secondary-bg !important;
            color: #fff !important;

            &:hover {
                background: #fff !important;

                span {
                    color: #1E1E1E !important;
                }
            }

            span {
                color: #fff !important;
                font-family: $font-family-sans-serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
            }
        }
    }
}


