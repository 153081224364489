// Fonts
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

// Variables
@import 'normalize.css';
@import 'variables';

// Bootstrap
@import 'bootstrap/scss/bootstrap';

@import "toastr/toastr";

@import 'general';
@import 'sections/header';
@import 'sections/slider';
@import 'sections/superdeals';
@import 'sections/why-choose-us';
@import 'sections/how-to-play';
@import 'sections/reviews';
@import 'sections/faq';
@import 'sections/footer';
@import 'sections/product-view';
@import 'sections/shopping-card';
@import 'sections/checkout';
@import 'sections/pages';
